import React, { Component, Fragment } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { BrowserRouter, useNavigate, Outlet } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { Login }  from './components/Login';
import { Home } from "./components/Home";
import { NoAccess } from "./components/NoAccess";

import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, withMsal } from "@azure/msal-react";

/*import AuthProvider from './AuthContext';*/
import RoutesComponent from './AppRoutes';

class App extends Component {
    static displayName = App.name;
    //static isAuthenticated = this.props.msalContext.accounts.length > 0;
    //static msalInstance = this.props.msalContext.instance;

    isAuthenticated() {
        return this.props.msalContext.accounts.length > 0;
    }

    accountRoles() {
        if (this.isAuthenticated()) {
            if (this.props.msalContext.accounts[0].idTokenClaims)
                return this.props.msalContext.accounts[0].idTokenClaims.roles;
        }
        return [];
    }

    componentDidMount() {

    }

    render() {
        return (
            <BrowserRouter>
                <Fragment>
                    <Layout>
                        <AuthenticatedTemplate>
                            <Routes>
                                {AppRoutes.map((route, index) => {
                                    const { element, ...rest } = route;
                                    const roles = this.accountRoles();
                                    if (route.roles && route.roles.length > 0) {
                                        if (route.roles.some(x => roles.includes(x)))
                                            return <Route key={index} {...rest} element={element} />;
                                        else
                                            return <Route key={index} path={route.path} element={<NoAccess />} />;
                                    }
                                    else
                                        return <Route key={index} {...rest} element={element} />;
                                })}
                                {/*            //<Route key={index} {...rest} element={<PrivateRoute Component={<Route key={index} {...rest} element={element} />} />} />*/}
                                {/*            //    return <Route key={index} {...rest} element={element} />;*/}
                                {/*            //    return <PrivateRoute key={index} {...rest} element={element} />;*/}
                                <Route path="/noaccess" element={<NoAccess />} />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </Routes>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                            <Routes>
                                <Route index={true} isPublic={true} element={<Home />} />
                                <Route path="/noaccess" element={<NoAccess />} />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </Routes>
                            {/*<Home />*/}
                        </UnauthenticatedTemplate>
                    </Layout>
                </Fragment>
            </BrowserRouter>
        );
    }

}
export default App = withMsal(App);

//export default function App() {
//    //const { loggedIn }  = useAuth();

//    return (
//        <AuthProvider store={store}>
//            {/*<Layout>*/}
//                    <RoutesComponent />
//            {/*</Layout>*/}
//        </AuthProvider>
//    );
//}

//const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => {
//    const auth = loggedIn;  

//    return !loggedIn ? <Component loggedIn={loggedIn} {...rest} /> : <Login />;
//};
//const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
//    <Route
//        {...rest}
//        render={(props) =>
//            loggedIn ? <Component {...props} /> : <Login />
//        }
//    />
//);
