import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPenToSquare, faCalendar, faClock, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';
import useApiFetch from '../../ApiFetch';
import { HtmlEditor } from '../shared/htmlEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function EventEditTitle(props) {
    const { eventTitle, saved } = props;

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(eventTitle ?? null);

    const [modal, setModal] = useState(false);

    const [validated, setValidated] = useState(false);

    const modalToggle = () => {
        if (!modal) {
            setTitle(eventTitle);
        }
        setModal(!modal);
        setValidated(false);
    };

    useEffect(() => {
        setLoading(true);
    }, [props]);

    const save = async () => {
        if (saved) {
            saved(title);
        }
        modalToggle();
    };

    return (
        <>
            <span className="ps-2 h5 align-top text-primary" style={{ lineHeight: '20px', cursor: 'pointer' }} onClick={modalToggle} ><FontAwesomeIcon icon={faPenToSquare} /></span>
            <Modal isOpen={modal} toggle={modalToggle} size="md" fade={true}>
                <ModalHeader toggle={modalToggle}>
                    <FontAwesomeIcon icon={faCalendar} className="pe-2" />Edit Title
                </ModalHeader>
                <ModalBody className="">
                    <input type="text" className="form-control form-control-lg" value={title} onChange={(e) => setTitle(e.target.value)} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        {/*<FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />*/}
                        Cancel
                    </Button>
                    <Button color="primary" onClick={save} disabled={eventTitle === title}>
                        <FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
