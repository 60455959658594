import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPenToSquare, faCalendar, faClock, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';
import useApiFetch from '../../ApiFetch';
import { HtmlEditor } from '../shared/htmlEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function EventEditLocation(props) {
    const { eventLocation, saved } = props;

    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState(eventLocation ?? null);

    const [modal, setModal] = useState(false);

    const [validated, setValidated] = useState(false);

    const modalToggle = () => {
        if (!modal) {
            setLocation(eventLocation);
        }
        setModal(!modal);
        setValidated(false);
    };

    useEffect(() => {
        setLoading(true);
    }, [props]);

    const save = async () => {
        if (saved) {
            saved(location);
        }
        modalToggle();
    };

    return (
        <>
            <span className="ps-2 h6 align-top text-primary" style={{ lineHeight: '20px', cursor: 'pointer' }} onClick={modalToggle} ><FontAwesomeIcon icon={faPenToSquare} /></span>
            <Modal isOpen={modal} toggle={modalToggle} size="md" fade={true}>
                <ModalHeader toggle={modalToggle}>
                    <FontAwesomeIcon icon={faCalendar} className="pe-2" />Edit Location
                </ModalHeader>
                <ModalBody className="">
                    <div className="row g-3">
                        <div className="col-md-6">
                            <label htmlFor="txtVenue" className="form-label">Venue:</label>
                            <input type="text" required className={`form-control`} id="txtVenue" value={location.venue} onChange={(e) => setLocation({ ...location, venue: e.target.value })} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="txtRoom" className="form-label">Room:</label>
                            <input type="text" required className={`form-control`} id="txtRoom" value={location.room} onChange={(e) => setLocation({ ...location, room: e.target.value })} />
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="txtAddress" className="form-label">Address:</label>
                            <input type="text" required className={`form-control`} id="txtAddress" value={location.address} onChange={(e) => setLocation({ ...location, address: e.target.value })} />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="txtCity" className="form-label">City:</label>
                            <input type="text" required className={`form-control`} id="txtCity" value={location.city} onChange={(e) => setLocation({ ...location, city: e.target.value })} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="txtState" className="form-label">State:</label>
                            <input type="text" required className={`form-control`} id="txtState" value={location.state} onChange={(e) => setLocation({ ...location, state: e.target.value })} />
                        </div>
                        <div className="col-md-3">
                            <label htmlFor="txtZip" className="form-label">Zip:</label>
                            <input type="text" required className={`form-control`} id="txtZip" value={location.zip} onChange={(e) => setLocation({ ...location, zip: e.target.value })} />
                        </div>


                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        {/*<FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />*/}
                        Cancel
                    </Button>
                    <Button color="primary" onClick={save} disabled={!(location.venue !== eventLocation.venue || location.room !== eventLocation.room || location.address !== eventLocation.address || location.city !== eventLocation.city || location.state !== eventLocation.state || location.zip !== eventLocation.zip)}>
                        <FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
