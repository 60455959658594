import { BrowserRouter, Route, Routes, RouterProvider, createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthContext";

import RequireAuth from '@auth-kit/react-router/RequireAuth'

import { Home } from "./components/Home";
import { Login } from "./components/Login";
import Events from "./components/events/index";
import Event from "./components/events/Event";

const AppRoutes = [
    {
        index: true,
        element: <Home />,
        isPublic: true
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/events/*',
        element: <Events />,
    },
    {
        path: '/events/:eventId',
        element: <Event />,
    },
    {
        path: '/events/new',
        element: <Event createNew />,
    },
];

export default AppRoutes;

//const Routes = () => {
//    const { token } = useAuth();

//    const publicRoutes = [
//        {
//            path: "/",
//            element: <Home />,
//        },
//    ];
//    const authenticatedRoutes = [
//        {
//            path: "/",
//            element: <ProtectedRoute />,
//            children: [
//                ...AppRoutes,
//                {
//                    path: "",
//                    element: <div>User Home Page</div>,
//                },
//                {
//                    path: "/register",
//                    element: <div>register</div>,
//                },
//                {
//                    path: "/logout",
//                    element: <div>Logout</div>,
//                },
//            ]
//        },
//    ];
//    const notAuthenticatedRoutes = [
//        {
//            path: "/",
//            element: <div>Home Page</div>,
//        },
//        {
//            path: "/login",
//            element: <Login />,
//        },
//    ];

//    const router = createBrowserRouter([
//        ...publicRoutes,
//        ...(!token ? notAuthenticatedRoutes : []),
//        ...authenticatedRoutes,
//    ]);

//    return <RouterProvider router={router} basename="/" />;
//};

/*export default Routes;*/
//import { Layout } from './components/Layout';

//const RoutesComponent = () => {
//    return (
//        /*<Layout>*/
//        <BrowserRouter>
//            <Layout>
//                <Routes>
//                    <Route path={'/'} element={<Home />} />
//                    <Route path={'/login'} element={<Login />} />
//                    <Route path={'/events'} element={<Events />} />
//                    <Route path={'/events/:eventId'} element={<Event />} />
//                    <Route path={'/events/new'} element={<Event createNew />} />
//                </Routes>
//            </Layout>
//        </BrowserRouter>
//        /*</Layout>*/
//    )
//};
//export default RoutesComponent;

const ProtectedRoute = () => {
    const { token } = useAuth();

    // Check if the user is authenticated
    if (!token) {
        // If not authenticated, redirect to the login page
        return <Navigate to="/login" />;
    }

    // If authenticated, render the child routes
    return <Outlet />;
};

/*export default AppRoutes;*/
