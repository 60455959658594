import React, { Component, useState, useEffect } from 'react';
import { faHeart, faStar, faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { faLock, faKey, faCamera, faMagnifyingGlass, faSpinner, faFileInvoice, faUsersLine, faBan, faBook, faFloppyDisk, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, Link } from 'react-router-dom';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import useApiFetch from '../ApiFetch';
import { useSearchParams } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';


export const Login = () => {
    const isAuthenticated = useIsAuthenticated();
    const [searchParams] = useSearchParams();
    const signIn = useSignIn();
    const navigate = useNavigate();
    const apiFetch = useApiFetch();

    let qEmail = searchParams.get('email');
    const [email, setEmail] = useState(qEmail ?? '');
    const [password, setPassword] = useState('');
    const [signingIn, setSigningIn] = useState(false);
    const [loginError, setLoginError] = useState(null);

    const [modalForgotPassword, setModalForgotPassword] = useState(false);
    const [resetPassEmail, setResetPassEmail] = useState('');
    const [resetting, setResetting] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    useEffect(() => {
        if (isAuthenticated())
            navigate('/');
    });

    const loginHandler = async (e) => {
        e.preventDefault();
        setSigningIn(true);
        // Assuming that, all network Request is successfull, and the user is authenticated


        //const presponse = await fetch('/api/auth/login', {
        //    method: 'POST',
        //    body: JSON.stringify({
        //        email: email,
        //        password: password,
        //    }),
        //    headers: {
        //        'Content-Type': 'application/json'
        //    },
        //}).catch(ex => {
        //    console.log('Caught Error')
        //});
        //const pdata = await presponse.json();

        setLoginError(null);
        await apiFetch.post('api/auth/login', {
            email: email,
            password: password,
        })
            .then((resp) => {
                if (resp.data) {
                    if (signIn({
                        auth: {
                            token: resp.data.token,
                            type: 'Bearer'
                        },
                        userState: { profile: resp.data.profile }
                    })) {
                        // If Login Successfull, then Redirect the user to secure route
                        navigate('/')
                    }
                    else {
                        // Else, there must be some error. So, throw an error
                        setLoginError("Could not login.  Try again.");
                        setTimeout(() => { setLoginError(null); }, 5000);
                        setPassword('');
                    }
                }
                else {
                    setLoginError('Invalid login.');
                    setTimeout(() => { setLoginError(null); }, 5000);
                    setPassword('');
                }
            })
            .catch(ex => {
                setLoginError('Error logging in.');
                setTimeout(() => { setLoginError(null); }, 5000);
                setPassword('');
                console.error('Caught Error', ex);
            })
            .finally(() => {
                setSigningIn(false);
            });

    }

    const modalForgotPasswordToggle = () => {
        setModalForgotPassword(!modalForgotPassword);
        setResetPassEmail('');
        setResetting(false);
        setEmailSent(false);
    };
    const resetPassword = async (e) => {
        e.preventDefault();
        setResetting(true);
        if (isEmail(resetPassEmail)) {
            const response = await apiFetch.post('api/auth/resetpasswordrequest', resetPassEmail, {
                headers: { 'Content-Type': 'application/json'}
            })
                .then(resp => {
                    setEmailSent(true);
                })
                .catch(ex => {
                    console.error('Caught Error');
            });
        }
        setResetting(false);
    };

    return (
        <>
            <div className="container">
            <div className="row d-flex mt-lg-5">
                <div className="col-auto m-auto login">
                    <form>
                            <div className="col-auto d-flex justify-content-center mb-4">
                            <div className="col-auto">
                                <img className="" src="./assets/images/hyetech_logo2_4color.png" alt="" height="100" />
                            </div>
                        </div>
                        <h1 className="h3 mb-3 fw-normal">Sign in</h1>

                        <div className="form-floating">
                            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <label htmlFor="floatingInput">Email address</label>
                        </div>
                        <div className="form-floating">
                            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            <label htmlFor="floatingPassword">Password</label>
                        </div>
                        <div className="text-start my-2">
                            <span className="link-secondary" style={{ cursor: "pointer" }} onClick={modalForgotPasswordToggle} >
                                Forgot Password?
                            </span>
                        </div>
                        {loginError ?
                            <div className="text-center mt-3">
                                <span className="text-danger">
                                    {loginError}
                                </span>
                            </div>
                            : null}
                        <button className="btn btn-primary w-100 py-2 mt-4" type="submit" onClick={loginHandler} disabled={!isEmail(email) || !(password.length > 0)}>
                            <FontAwesomeIcon icon={signingIn ? faSpinner : faLock} spin={signingIn} className="me-2" />
                            Sign in
                        </button>
                        <div className="text-center pt-2">
                            <span className="text-secondary">
                                Don't have an account? <Link className="link-secondary fw-bold link-underline-opacity-0" to="/profile/register">Sign Up now!</Link>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
            <Modal isOpen={modalForgotPassword} toggle={modalForgotPasswordToggle} size="md" fade={true}>
                <ModalHeader toggle={modalForgotPasswordToggle}>
                    <FontAwesomeIcon icon={faKey} className="pe-2" />Forgot Password
                </ModalHeader>
                <ModalBody className="">
                    <form onSubmit={resetPassword}>
                        {emailSent ?
                            <div className="mb-3">
                                <h2 className="h3">Password Reset</h2>
                                <h3 className="fs-6 fw-normal text-secondary my-3">An email has been sent with instructions to reset your password.</h3>
                            </div>
                            :
                            <>
                                <div className="mb-3">
                                    <h2 className="h3">Password Reset</h2>
                                    <h3 className="fs-6 fw-normal text-secondary my-3">Provide the email address associated with your account to reset your password.</h3>
                                </div>
                                <div className="form-floating">
                                    <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" value={resetPassEmail} onChange={(e) => setResetPassEmail(e.target.value)} />
                                    <label htmlFor="floatingInput">Email address</label>
                                </div>
                            </>
                        }
                    </form>
                </ModalBody>
                {emailSent ? null :
                    <ModalFooter>
                        <Button color="primary" disabled={resetPassEmail === '' || !isEmail(resetPassEmail)} onClick={resetPassword}>
                            <FontAwesomeIcon icon={resetting ? faSpinner : faKey} spin={resetting} className="me-2" />
                            Reset Password
                        </Button>
                    </ModalFooter>
                }
            </Modal>
            </div>
        </>
    );
}
