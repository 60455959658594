import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPenToSquare, faCalendar, faClock, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';
import useApiFetch from '../../ApiFetch';
import { HtmlEditor } from '../shared/htmlEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function EventEditDescription(props) {
    const { eventDescription, saved } = props;

    const [loading, setLoading] = useState(false);
    const [desc, setDesc] = useState(eventDescription ?? null);

    const [modal, setModal] = useState(false);

    const [validated, setValidated] = useState(false);
    const [registering, setRegistering] = useState(false);


    const modalToggle = () => {
        if (!modal) {
            setDesc(eventDescription);
        }
        setModal(!modal)
        setValidated(false);
        setRegistering(false);
    };

    useEffect(() => {
        setLoading(true);
        setRegistering(false);
    }, [props]);

    const saveDesc = async () => {
        if (saved) {
            saved(desc);
        }
        modalToggle();
    };

    return (
        <>
            <span className="ps-2 h5 align-top text-primary" style={{ lineHeight: '20px', cursor: 'pointer' }} onClick={modalToggle} ><FontAwesomeIcon icon={faPenToSquare} /></span>
            <Modal isOpen={modal} toggle={modalToggle} size="xl" fade={true}>
                <ModalHeader toggle={modalToggle}>
                    <FontAwesomeIcon icon={faCalendar} className="pe-2" />Edit Description
                </ModalHeader>
                <ModalBody className="">
                    <textarea className="form-control" rows="10" style={{
                        "minWidth": "100%",
                        "height": "calc(100vh - 225px)",
                        "maxHeight": "1200px"
                    }} value={desc} onChange={(e) => setDesc(e.target.value)} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        {/*<FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />*/}
                        Cancel
                    </Button>
                    <Button color="primary" onClick={saveDesc} disabled={eventDescription === desc}>
                        <FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
