import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPenToSquare, faCalendar, faClock, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';
import useApiFetch from '../../ApiFetch';
import { HtmlEditor } from '../shared/htmlEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function EventEditImage(props) {
    const { eventImageUrl, saved } = props;

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(eventImageUrl ?? null);

    const [modal, setModal] = useState(false);

    const [validated, setValidated] = useState(false);

    const modalToggle = () => {
        if (!modal) {
            setImageUrl(eventImageUrl);
        }
        setModal(!modal);
        setValidated(false);
    };

    useEffect(() => {
        setLoading(true);
    }, [props]);

    const save = async () => {
        if (saved) {
            saved(imageUrl);
        }
        modalToggle();
    };

    return (
        <>
            <span className="position-absolute top-50 start-50 translate-middle bg-light-subtle opacity-50 border border-3 rounded border-secondary p-2 display-4 align-top text-primary" style={{ lineHeight: '20px', cursor: 'pointer' }} onClick={modalToggle} ><FontAwesomeIcon icon={faPenToSquare} /></span>
            <Modal isOpen={modal} toggle={modalToggle} size="md" fade={true}>
                <ModalHeader toggle={modalToggle}>
                    <FontAwesomeIcon icon={faCalendar} className="pe-2" />Edit Image
                </ModalHeader>
                <ModalBody className="text-center">
                    <input type="text" className="form-control" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                    <img style={{ maxHeight: '400px', maxWidth: '100%' }} src={imageUrl ? imageUrl : './assets/images/hyetech_logo2_4color.png'} className="img-fluid mt-3 border-2 rounded border-secondary-subtle " alt="" />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        {/*<FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />*/}
                        Cancel
                    </Button>
                    <Button color="primary" onClick={save} disabled={eventImageUrl === imageUrl}>
                        <FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
