import React, { Component } from 'react';
import { faHeart, faStar, faTrashCan, faPenToSquare  } from '@fortawesome/free-regular-svg-icons';
import { faCalendar, faLock, faChartPie, faMagnifyingGlass, faArrowRight, faSpinner, faFileInvoice, faUsersLine, faBan, faBook, faFloppyDisk, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, Link } from 'react-router-dom';
import EventHero from "./events/EventHero";
import HomeEventCards from "./events/HomeEventCards";
import StayConnected from './StayConnected';
import { MsalContext, useIsAuthenticated, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, withMsal } from '@azure/msal-react';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { loginRequest } from "../authConfig";

export class Home extends Component {
    constructor(props) {
        super(props)
    }
    static displayName = Home.name;

    handleLogin(e) {
        e.preventDefault();
        //const msalInstance = useMsal();
        alert('login');
    }

    

    render() {

        return (
            <>
                <div className="container py-0">
                    <div className="row align-items-md-stretch pt-2">
                        <UnauthenticatedTemplate>
                            <div class="px-4 py-1 my-1 text-center">
                                <img class="d-block mx-auto mb-0" src="/assets/images/hyetech_logo2_4color.png" alt="" width="172" />
                                <h1 class="display-5 fw-bold text-body-emphasis mb-5">Welcome</h1>
                                <div class="col-lg-6 mx-auto">
                                    <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
                                        <LoginButton />
                                    </div>
                                </div>
                            </div>
                        </UnauthenticatedTemplate>
                        <AuthenticatedTemplate>
                            <div className="col-md-6">
                                <div className="h-100 p-5 bg-body-tertiary border rounded-3">
                                    <h2><FontAwesomeIcon icon={faCalendar} className="pe-2" />Events</h2>
                                    <p>Manage events, event details and schedules.</p>
                                    <Link to="/events" className="btn btn-outline-secondary me-1">Go to Events</Link>
                                </div>
                            </div>
                        </AuthenticatedTemplate>
                    </div>
                </div>
            </>
        );
    }
}
function LoginButton() {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest);
    }


    return (
        <Button color="primary" size="lg" className="px-4 gap-3" onClick={handleLogin}>
            <FontAwesomeIcon icon={faLock} className="me-2" />
            Login
        </Button>
    );
};

