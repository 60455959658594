import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import moment from 'moment';
import DarkModeDropdown from './DarkModeDropdown';
import { Link } from 'react-router-dom';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
        <div className="content-wrap">
            <NavMenu />
            <div tag="main">
                {this.props.children}
            </div>
            {/*<div class="dropdown position-fixed bottom-0 end-0 mb-3 me-3 bd-mode-toggle">*/}

            {/*    <button class="btn btn-bd-primary py-2 dropdown-toggle d-flex align-items-center" id="bd-theme" type="button" aria-expanded="false" data-bs-toggle="dropdown" aria-label="Toggle theme (light)">*/}
            {/*        <svg class="bi my-1 theme-icon-active" width="1em" height="1em"><use href="#sun-fill"></use></svg>*/}
            {/*        <span class="visually-hidden" id="bd-theme-text">Toggle theme</span>*/}
            {/*    </button>*/}
            {/*    <ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="bd-theme-text">*/}
            {/*        <li>*/}
            {/*            <button type="button" class="dropdown-item d-flex align-items-center active" data-bs-theme-value="light" aria-pressed="true">*/}
            {/*                <svg class="bi me-2 opacity-50" width="1em" height="1em"><use href="#sun-fill"></use></svg>*/}
            {/*                Light*/}
            {/*                <svg class="bi ms-auto d-none" width="1em" height="1em"><use href="#check2"></use></svg>*/}
            {/*            </button>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="dark" aria-pressed="false">*/}
            {/*                <svg class="bi me-2 opacity-50" width="1em" height="1em"><use href="#moon-stars-fill"></use></svg>*/}
            {/*                Dark*/}
            {/*                <svg class="bi ms-auto d-none" width="1em" height="1em"><use href="#check2"></use></svg>*/}
            {/*            </button>*/}
            {/*        </li>*/}
            {/*        <li>*/}
            {/*            <button type="button" class="dropdown-item d-flex align-items-center" data-bs-theme-value="auto" aria-pressed="false">*/}
            {/*                <svg class="bi me-2 opacity-50" width="1em" height="1em"><use href="#circle-half"></use></svg>*/}
            {/*                Auto*/}
            {/*                <svg class="bi ms-auto d-none" width="1em" height="1em"><use href="#check2"></use></svg>*/}
            {/*            </button>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}
            {/*<footer className="border-top text-muted mt-auto py-1">*/}
            {/*    <div className="container">*/}
            {/*        <div className="d-flex justify-content-between">*/}
            {/*            <div>*/}
            {/*                &copy; {moment().year()} - Forecasting Tool*/}
            {/*            </div>*/}
            {/*            <div>*/}
            {/*                <small>*/}
            {/*                    asdf*/}
            {/*                </small>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</footer>*/}

            <footer className="py-2 border-top">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center" >
                    <p className="col-md-4 mb-0 text-body-secondary">&copy; {moment().year()} Hye Tech Network & Security Solutions, LLC</p>

                    <a href="/" className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                        <img src="./assets/images/hyetech_logo2_4color.png" width="40" alt="" />
                    </a>

                    <ul className="nav col-md-4 justify-content-end">
                        <li className="nav-item">
                            <Link to='/' className="nav-link px-2 text-body-secondary" onClick={(e) => document.documentElement.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "instant", // Optional if you want to skip the scrolling animation
                            })}>
                                Home
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/events' className="nav-link px-2 text-body-secondary" onClick={(e) => document.documentElement.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "instant", // Optional if you want to skip the scrolling animation
                            })}>
                                Events
                            </Link>
                        </li>
                        </ul>
                    </div>
                </div>
            </footer>
            <DarkModeDropdown />
        </div>
    );
  }
}
