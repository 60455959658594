import React, { useState, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import useApiFetch from '../../ApiFetch';


import { Link, Route, Routes } from 'react-router-dom';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';

//import { GetAuthUsername } from "../AuthUsername";

//import Header from "./Header";
//import SubMenu from "./SubMenu";
//import Find from "./Find";

import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
import { faHeart, faStar } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faCalendarPlus, faXmark, faBan, faArrowRight, faCalendarDays, faUser, faMagnifyingGlass, faSpinner } from '@fortawesome/free-solid-svg-icons';
/*import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';*/
//import { DataTableFunc } from "../DataTableFunc"
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import EventCard from './EventCard';
import StayConnected from '../StayConnected';
import TimeFromDate from '../shared/TimeFromDate';


export default function Events() {
    const apiFetch = useApiFetch();

    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [months, setMonths] = useState([]);
    const [curTheme, setCurTheme] = useState(localStorage.getItem('theme'));
    const [includeInactive, setIncludeInactive] = useState(false);

    const [modalCompetitorDetail, setModalCompetitorDetail] = useState(false);

    //const authUsername = GetAuthUsername();

    const toggleCompetitorDetail = () => setModalCompetitorDetail(!modalCompetitorDetail);

    useEffect(() => {
        setLoading(true);
        getData();
    }, [includeInactive]);

    window.addEventListener('theme', () => {
        setCurTheme(localStorage.getItem('theme'));
        // ...
    })

    const sortDate = async (dateA, dateB) => {
        const a = dateA ? moment(dateA) : 0;
        const b = dateB ? moment(dateB) : 0;

        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };
    const getData = async () => {
        await apiFetch.get('/api/event' + (includeInactive ? '?includeInactive=true' : ''))
            .then(resp => {
                let data = resp.data;
                if (data && data.length > 0) {
                    data = data.sort(function (a, b) {
                        // Turn your strings into dates, and then subtract them
                        // to get a value that is either negative, positive, or zero.
                        return new Date(a.startDate) - new Date(b.startDate);
                    })
                }
                setEvents(resp.data);
                if (resp.data && resp.data.length > 0) {
                    let dates = resp.data.map(e => {
                        let thisMonth = moment().set('month', moment(e.startDate).month());
                        thisMonth = thisMonth.set('date', 1);
                        thisMonth = thisMonth.set('year', moment(e.startDate).year());
                        thisMonth = thisMonth.startOf('day');
                        return thisMonth.format('L');
                    });

                    let datesArray = Array.from([...new Set(dates)]).filter((v, i, self) => {
                        // It returns the index of the first
                        // instance of each value
                        return i === self.indexOf(moment(v).format('L'));
                    })

                    setMonths(datesArray);

                }
                setLoading(false);
            });
    };
    return (
        <>
            {/*<div className="b-example-divider mb-4">*/}
            {/*    <div className="container-xl">*/}
            {/*        <h2>*/}
            {/*            <FontAwesomeIcon icon={faCalendarDays} className="me-3" />*/}
            {/*            Events*/}
            {/*        </h2>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="container-xl pb-4">
                <h1>Events</h1>

                <div className="row d-flex justify-content-end">
                    <Link color="primary" className="col-auto btn btn-primary me-4" to='/events/new'>
                        <FontAwesomeIcon icon={faCalendarPlus} spin={false} className="me-2" />
                        Create Event
                    </Link>
                    <div className="col-auto align-self-center form-check form-switch form-check-reverse">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckReverse" value={includeInactive} onChange={() => setIncludeInactive(!includeInactive)} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckReverse">Include Deleted</label>
                    </div>
                </div>
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th className="">&nbsp;</th>
                            <th className="">Id</th>
                            <th className="">Code</th>
                            <th className="">Title</th>
                            <th className="">Short Description</th>
                            <th className="text-end">Start Date</th>
                            <th className="text-end">Start Time</th>
                            <th className="text-end">End Date</th>
                            <th className="text-end">End Time</th>
                            <th className="text-center">Date Created</th>
                            <th className="text-center">Published</th>
                            <th className="text-center">Deleted</th>
                            {/*<th className="">Actions</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {events && events.length > 0
                            ?
                            events.map((e, i) =>
                                <tr key={i} className={`${!e.active ? 'table-danger' : ''}`}>
                                    <td>
                                        <Link to={'/events/' + e.id}><FontAwesomeIcon icon={faArrowRight} /></Link>
                                    </td>
                                    <td>
                                        {e.id}
                                    </td>
                                    <td>
                                        {e.code}
                                    </td>
                                    <td>
                                        {e.title}
                                    </td>
                                    <td>
                                        <div>
                                            {e.shortDescription}
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        {e.startDate ? moment(e.startDate).format("M/D/yyyy") : null}
                                    </td>
                                    <td className="text-end">
                                        {e.startTime ? moment(e.startTime, 'HH:mm:ss').format('h:mm A') : null}
                                    </td>
                                    <td className="text-end">
                                        {e.endDate ? moment(e.endDate).format("M/D/yyyy") : null}
                                    </td>
                                    <td className="text-end">
                                        {e.endTime ? moment(e.endTime, 'HH:mm:ss').format('h:mm A') : null}
                                    </td>
                                    <td className="text-center">
                                        <TimeFromDate date={e.dateCreated} />
                                    </td>
                                    <td className="text-center">
                                        {e.isPublished ? <FontAwesomeIcon icon={faCheck} className="text-success" /> : <FontAwesomeIcon icon={faXmark} className="text-danger" />}
                                    </td>
                                    <td className="text-center">
                                        {!e.active ? <FontAwesomeIcon icon={faBan} className="text-danger" /> : <FontAwesomeIcon icon={faCheck} className="text-success" />}
                                    </td>
                                </tr>
                            )
                            :
                            <tr>
                                <td colSpan="12" className="text-center py-5">
                                    {loading ?
                                        <>
                                            <div className="my-3">
                                                <div className="p-3 text-center">
                                                    <div className="container py-3 text-muted">
                                                        <h1 className="display-1 text-body-muted"><FontAwesomeIcon icon={faSpinner} spin /></h1>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>There are no events.</>
                                    }
                                    
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    );

}
