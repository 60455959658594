import { useState, useEffect } from 'react';
import {
    UncontrolledDropdown, DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faCircleHalfStroke, faSun, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

const IS_SERVER = typeof window === 'undefined';

let storedTheme = IS_SERVER ? 'light' : localStorage.getItem('theme');

const arrayOfThemes = [
    { name: 'Light', icon: faSun},
    { name: 'Dark', icon: faMoon},
    { name: 'Auto', icon: faCircleHalfStroke},
    // { name: 'Blue', icon: '🟦' }, //Add your own themes!
];

//Modifies the html root element
function modifyDOM(theme) {
    if (
        theme === 'auto' &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
        document.documentElement.setAttribute('data-bs-theme', 'dark');
    } else {
        document.documentElement.setAttribute('data-bs-theme', theme);
    }
}

export default function DarkModeDropdown() {
    const [mode, setMode] = useState(getPreferredTheme());

    useEffect(() => {
        if (IS_SERVER) return;
        modifyDOM(mode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getPreferredTheme() {
        if (storedTheme) {
            return storedTheme;
        }

        return window.matchMedia('(prefers-color-scheme: dark)').matches
            ? 'dark'
            : 'light';
    }

    function setPreferredTheme(theme) {
        modifyDOM(theme)

        localStorage.setItem('theme', theme);
        setMode(theme);
    }

    return (
        <div >
            <UncontrolledDropdown tag="div" className="position-fixed bottom-0 end-0 mb-3 me-3">
                <DropdownToggle caret className="btn btn-secondary py-2 dropdown-toggle d-flex align-items-center" tag="button">
                    <FontAwesomeIcon icon=
                        {
                            arrayOfThemes.find((theme) => theme.name.toLowerCase() === mode)
                                ?.icon
                        } />
                </DropdownToggle>
                <DropdownMenu flip tag="ul">
                    {arrayOfThemes.map((theme) => {
                        const active = mode === theme.name.toLowerCase();
                        return (
                            <li key={theme.name} onClick={() => {
                                setPreferredTheme(theme.name.toLocaleLowerCase());
                            }}>
                                <button type="button" className={`dropdown-item d-flex align-items-center ${active ? 'active' : ''}`}>
                                    <FontAwesomeIcon icon={theme.icon} className="me-2 opacity-50" fixedWidth />
                                    {theme.name}
                                    {active ? <FontAwesomeIcon icon={faCheck} className="ms-auto" /> : ''}
                                </button>
                            </li>
                        );
                    })}

                </DropdownMenu>
            </UncontrolledDropdown>
        </div>

    );
}