import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUsers, faPenToSquare, faCalendar, faClock, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';
import useApiFetch from '../../ApiFetch';
import { HtmlEditor } from '../shared/htmlEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TimeFromDate from '../shared/TimeFromDate';
import DownloadCSV from '../shared/DownloadCSV';
import { CSVLink, CSVDownload } from "react-csv";

export default function EventRegistrations(props) {
    const { eventId } = props;
    const apiFetch = useApiFetch();

    const [loading, setLoading] = useState(false);
    const [registrations, setRegistrations] = useState([]);

    const [modal, setModal] = useState(false);

    const [validated, setValidated] = useState(false);

    const modalToggle = () => {
        if (!modal) {
            getData();
        }
        setModal(!modal);
        setValidated(false);
    };

    useEffect(() => {
        //setLoading(true);
    }, []);

    const getData = async () => {
        await apiFetch.get('/api/event/' + encodeURIComponent(eventId) + '/registrations')
            .then(resp => {
                setRegistrations(resp.data);
                setLoading(false);
            });
    };

    return (
        <>
            <FontAwesomeIcon icon={faUsers} title='View Registrations' className='text-primary lh-1 px-1' size="lg" style={{ cursor: 'pointer' }} onClick={modalToggle} />
            <Modal isOpen={modal} toggle={modalToggle} size="lg" fade={true}>
                <ModalHeader toggle={modalToggle}>
                    <FontAwesomeIcon icon={faCalendar} className="pe-2" />Event Registrations
                </ModalHeader>
                <ModalBody className="table-responsive" style={{ display: 'block', width: '100%', overflowX: 'auto', maxHeight: 'calc(-124px + 100vh)' }}>
                    <ol className="list-group breadcrumb me-auto float-end">
                        <li className="breadcrumb-item">
                            <CSVLink data={registrations ? registrations.map(r => {
                                return {
                                    email: r.email,
                                    firstName: r.firstName,
                                    lastName: r.lastName,
                                    company: r.company,
                                    phone: r.phone,
                                    dateRegistered: moment(r.dateCreated).format("M/D/yyyy h:mm:ss A")
                                }
                            }) : null} headers={[
                                { label: "Email", key: "email" },
                                { label: "First", key: "firstName" },
                                { label: "Last", key: "lastName" },
                                { label: "Company", key: "company" },
                                { label: "Phone", key: "phone" },
                                { label: "Date Registered", key: "dateRegistered" }
                                ]} className="link-body-emphasis fw-semibold text-decoration-none" filename="RegistrationData">
                                <FontAwesomeIcon icon={faDownload} className="pe-2" />
                                Download Data
                            </CSVLink>
                        </li>
                    </ol>
                    <div>
                    {/*<CSVLink data={registrations ? registrations.map(r => {*/}
                    {/*    return {*/}
                    {/*        email: r.email,*/}
                    {/*        firstName: r.firstName,*/}
                    {/*        lastName: r.lastName,*/}
                    {/*        company: r.company,*/}
                    {/*        phone: r.phone,*/}
                    {/*        dateRegistered: moment(r.dateCreated).format("M/D/yyyy h:mm:ss A")*/}
                    {/*    }*/}
                    {/*}) : null} headers={[*/}
                    {/*    { label: "Email", key: "email" },*/}
                    {/*    { label: "First", key: "firstName" },*/}
                    {/*    { label: "Last", key: "lastName" },*/}
                    {/*    { label: "Company", key: "company" },*/}
                    {/*    { label: "Phone", key: "phone" },*/}
                    {/*    { label: "Date Registered", key: "dateRegistered" }*/}
                    {/*        ]} className="btn btn-primary text-end float-end" filename="RegistrationData">Click to Download</CSVLink>*/}
                    </div>
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>
                                <th>Email</th>
                                <th>First</th>
                                <th>Last</th>
                                <th>Company</th>
                                <th>Phone</th>
                                <th className="text-center">Date Registered</th>
                            </tr>
                        </thead>
                        <tbody>
                            {registrations && registrations.length > 0 ?
                                registrations.map((r, i) => 
                                    <tr key={i}>
                                        <td>{r.email}</td>
                                        <td>{r.firstName}</td>
                                        <td>{r.lastName}</td>
                                        <td>{r.company}</td>
                                        <td>{r.phone}</td>
                                        <td className="text-center"><TimeFromDate date={r.dateCreated} /></td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td colSpan="6" className="text-center">There are no registration records.</td>
                                </tr>
                                }
                        </tbody>
                    </table>
                </ModalBody>
                {/*<ModalFooter>*/}
                {/*    <Button color="secondary" onClick={modalToggle}>*/}
                {/*        */}{/*<FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />*/}
                {/*        Cancel*/}
                {/*    </Button>*/}
                {/*    <Button color="primary" onClick={modalToggle}>*/}
                {/*        <FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />*/}
                {/*        Save*/}
                {/*    </Button>*/}
                {/*</ModalFooter>*/}
            </Modal>
        </>
    );
};
