import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPenToSquare, faCalendar, faClock, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';
import useApiFetch from '../../ApiFetch';
import { HtmlEditor } from '../shared/htmlEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function EventEditDates(props) {
    const { eventDates, saved } = props;

    const [loading, setLoading] = useState(false);
    const [dates, setDates] = useState(eventDates ?? null);

    const [modal, setModal] = useState(false);

    const [validated, setValidated] = useState(false);

    const modalToggle = () => {
        if (!modal) {
            setDates(eventDates);
        }
        setModal(!modal);
        setValidated(false);
    };

    useEffect(() => {
        setLoading(true);
    }, [props]);

    const save = async () => {
        if (saved) {
            saved(dates);
        }
        modalToggle();
    };

    return (
        <>
            <span className="ps-2 align-top h6 text-primary" style={{ cursor: 'pointer' }} onClick={modalToggle} ><FontAwesomeIcon icon={faPenToSquare} /></span>
            <Modal isOpen={modal} toggle={modalToggle} size="md" fade={true}>
                <ModalHeader toggle={modalToggle}>
                    <FontAwesomeIcon icon={faCalendar} className="pe-2" />Edit Dates
                </ModalHeader>
                <ModalBody className="">
                    <div className="row g-3">
                        <div className="col-sm-6">
                            <label htmlFor="txtStartDate" className="form-label">Start Date:</label>
                            <input type="date" required className={`form-control`} id="txtStartDate" value={dates && dates.startDate ? moment(dates.startDate).format('yyyy-MM-DD') : ''} onChange={(e) => setDates({ ...dates, startDate: moment(e.target.value).isValid() ? e.target.value : null })} />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="txtStartTime" className="form-label">Start Time:</label>
                            <input type="time" required className={`form-control`} id="txtStartTime" value={dates && dates.startTime ? moment(dates.startTime, 'HH:mm:ss').format('HH:mm') : ''} onChange={(e) => setDates({ ...dates, startTime: moment(e.target.value, 'HH:mm').isValid() ? moment(e.target.value, 'HH:mm').format('HH:mm:ss') : null })} />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="txtEndDate" className="form-label">End Date:</label>
                            <input type="date" required className={`form-control`} id="txtEndDate" value={dates && dates.endDate ? moment(dates.endDate).format('yyyy-MM-DD') : ''} onChange={(e) => setDates({ ...dates, endDate: moment(e.target.value).isValid() ? e.target.value : null }) } />
                        </div>
                        <div className="col-sm-6">
                            <label htmlFor="txtEndTime" className="form-label">End Time:</label>
                            <input type="time" required className={`form-control`} id="txtEndTime" value={dates && dates.endTime ? moment(dates.endTime, 'HH:mm:ss').format('HH:mm') : ''} onChange={(e) => setDates({ ...dates, endTime: moment(e.target.value, 'HH:mm').isValid() ? moment(e.target.value, 'HH:mm').format('HH:mm:ss') : null })} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={modalToggle}>
                        {/*<FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />*/}
                        Cancel
                    </Button>
                    <Button color="primary" onClick={save} disabled={!(dates.startDate !== eventDates.startDate || dates.startTime !== eventDates.startTime || dates.endDate !== eventDates.endDate || dates.endTime !== eventDates.endTime)}>
                        <FontAwesomeIcon icon={faFloppyDisk} spin={false} className="me-2" />
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
