import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faUsers, faArrowLeft, faTrash, faEye, faEyeSlash, faBullhorn, faRotateLeft, faFloppyDisk, faCalendar, faClock, faLocationDot, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast, ToastHeader, ToastBody } from 'reactstrap';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';
import useApiFetch from '../../ApiFetch';




import { HtmlEditor } from '../shared/htmlEditor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EventEditDescription from './EventEditDescription';
import EventEditTitle from './EventEditTitle';
import TimeFromDate from '../shared/TimeFromDate';
import EventEditImage from './EventEditImage';
import EventEditDates from './EventEditDates';
import EventEditLocation from './EventEditLocation';
import EventRegistrations from './EventRegistrations';

export default function Event(props) {
    const { createNew } = props;
    const { eventId } = useParams();
    const navigate = useNavigate();
    const apiFetch = useApiFetch();

    const [loading, setLoading] = useState(false);
    const [eventData, setEventData] = useState([]);
    const [invalidCode, setInvalidCode] = useState(false);
    const [modal, setModal] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const [validated, setValidated] = useState(false);
    const [registration, setRegistration] = useState({ firstName: '', lastName: '', email: '', company: '', phone: '' });
    const [registering, setRegistering] = useState(false);
    const [registeredData, setRegisteredData] = useState(null);

    const [testDesc, setTestDesc] = useState('');

    
    useEffect(() => {
        setLoading(true);
        setRegistering(false);
        if (createNew) {
            setLoading(false);
        }
        else {
            getData();
            setHasChanges(false);
        }
    }, [eventId]);
    const getData = async () => {
        await apiFetch.get('/api/event/' + encodeURIComponent(eventId))
            .then(resp => {
                setEventData(resp.data);
                setTestDesc(resp.data.description);
                setLoading(false);
            });
    };
    const undoChanges = async () => {
        setLoading(true);
        getData();
        setHasChanges(false);
        setInvalidCode(false);
    };
    const getEventDates = () => {
        if (eventData && (eventData.startDate || eventData.endDate)) {
            if (eventData.endDate) {
                //if we have an end date, check to see if the start and end dates are the same, if they are, show just one date
                if (eventData.endDate === eventData.startDate) {
                    return moment(eventData.startDate).format('MMMM D');
                }
                else {
                    //if the month is the same, show Month Start-End, otherwise, show Month Start - Month End
                    if (moment(eventData.startDate).month() === moment(eventData.endDate).month()) {
                        return moment(eventData.startDate).format('MMMM') + ' ' + moment(eventData.startDate).format('D') + '-' + moment(eventData.endDate).format('D');
                    }
                    else {
                        return moment(eventData.startDate).format('MMMM D') + '-' + moment(eventData.endDate).format('MMMM D');
                    }
                }
            }
            else {
                return moment(eventData.startDate).format('MMMM D');
            }
        }
        else
            return null;

    };
    const getEventTimes = () => {
        if (eventData && eventData.startTime) {
            if (eventData.endTime) {
                //if we have an end date, check to see if the start and end dates are the same, if they are, show just one date
                if (eventData.endTime === eventData.startTime) {
                    return moment(eventData.startTime, 'HH:mm:ss').format('h:mm A');
                }
                else {
                    return moment(eventData.startTime, 'HH:mm:ss').format('h:mm A') + '-' + moment(eventData.endTime, 'HH:mm:ss').format('h:mm A');
                }
            }
            else {
                return moment(eventData.startTime, 'HH:mm:ss').format('h:mm A');
            }
        }
        else {
            return null;
        }

    };
    const createEvent = async () => {
        var codeCheck = await checkCode();
        if (codeCheck) {
            setInvalidCode(true);
            return;
        }
        if (hasChanges && eventData && eventData.code) {
            await apiFetch.post('/api/event/', eventData)
                .then(resp => {
                    if (resp.data) {
                        navigate('/events/' + resp.data.id)
                        hasChanges(false);
                    }
                    else {
                    }
                })
                .catch(err => {
                    if (err.response && err.response && err.response.data === "Invalid Code")
                        setInvalidCode(true);
                });
        }
    };
    const saveEvent = async () => {
        if (hasChanges && !invalidCode) {
            await apiFetch.put('/api/event/' + encodeURIComponent(eventId), eventData)
                .then(resp => {
                    if (resp.data) {
                        //we have a valid code;
                        setLoading(false);
                        setEventData(resp.data);
                        setHasChanges(false);
                    }
                    else {
                    }
                })
                .catch(err => {
                    if (err.response && err.response && err.response.data === "Invalid Code")
                        setInvalidCode(true);
                });
        }
    };
    const savedDescription = (value) => {
        setEventData({ ...eventData, description: value },);
        setHasChanges(true);
    };
    const savedShortDescription = (value) => {
        setEventData({ ...eventData, shortDescription: value },)
        setHasChanges(true);
    };
    const savedImage = (value) => {
        setEventData({ ...eventData, imageUrl: value },)
        setHasChanges(true);
    };
    const savedTitle = (value) => {
        setEventData({ ...eventData, title: value },)
        setHasChanges(true);
    };
    const savedDates = (value) => {
        setEventData({ ...eventData, ...value },)
        setHasChanges(true);
    };
    const savedLocation = (value) => {
        setEventData({ ...eventData, ...value },)
        setHasChanges(true);
    };
    const checkCode = async (e) => {
        if (!e && !eventData.code) {
            return true;
        }
        if (e && !e.target.value) {
            return true;
        }
        return await apiFetch.get('/api/event/' + (createNew ? '' : encodeURIComponent(eventId) + '/') + 'isvalidcode?code=' + (e ? e.target.value : eventData.code))
            .then(resp => {
                if (resp.data) {
                    //we have a valid code;
                    setInvalidCode(false);
                    return false;
                }
                else {
                    setInvalidCode(true);
                    return true;
                }
            });
            ;
    };
    const activate = async () => {
        await apiFetch.put('/api/event/' + encodeURIComponent(eventId) + '/activate', eventData)
            .then(resp => {
                if (resp.data) {
                    //we have a valid code;
                    setLoading(false);
                    setEventData(resp.data);
                }
                else {
                }
            })
            .catch(err => {
                if (err.response && err.response && err.response.data === "Invalid Code")
                    setInvalidCode(true);
            });

    };
    const publish = async () => {
        await apiFetch.put('/api/event/' + encodeURIComponent(eventId) + '/publish', eventData)
            .then(resp => {
                if (resp.data) {
                    //we have a valid code;
                    setLoading(false);
                    setEventData(resp.data);
                }
                else {
                }
            })
            .catch(err => {
                if (err.response && err.response && err.response.data === "Invalid Code")
                    setInvalidCode(true);
            });
    };

    return (
        <>
            <div className="container-xl col-xxl-10 px-4 pt-1 pb-5">
                <div className="d-flex text-end pb-3">
                    {/*<nav aria-label="breadcrumb">*/}
                    <ol className="list-group breadcrumb p-2 me-auto">
                        <li className="breadcrumb-item">
                            <Link to="/events" className="link-body-emphasis fw-semibold text-decoration-none">
                                <FontAwesomeIcon icon={faArrowLeft} className="pe-2" />
                                <span className="">Back</span>
                            </Link>
                        </li>
                    </ol>
                    {/*</nav>*/}
                    <ul className="list-group col-auto p-0">
                        <li className="list-group-item py-1">
                            {eventData.dateCreated ?
                                <>
                                    <span className="text-muted">Created:</span> <TimeFromDate date={eventData.dateCreated} />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </>
                                :
                                null
                            }
                            {/*<button className="btn btn-lg py-0 px-1">*/}
                            {/*    <FontAwesomeIcon icon={faEye} className="text-primary" />*/}
                            {/*</button>*/}
                            {/*<button className="btn btn-lg py-0 px-1">*/}
                            {/*    <FontAwesomeIcon icon={faEyeSlash} className="text-primary" />*/}
                            {/*</button>*/}
                            {!createNew ?
                                <>
                                    <EventRegistrations eventId={eventId} />
                                    <FontAwesomeIcon icon={faBullhorn} title={`${!eventData.isPublished ? 'Publish' : 'Unpublish'}`} className={`${eventData.isPublished ? 'text-secondary' : 'text-primary'} lh-1 px-1`} size="lg" style={{ cursor: 'pointer' }} onClick={publish} />
                                    <FontAwesomeIcon icon={faTrash} title={`${eventData.active ? 'Delete' : 'Undelete'}`} className={`${eventData.active ? 'text-primary' : 'text-danger'} lh-1 px-1`} size="lg" style={{ cursor: 'pointer' }} onClick={activate} />
                                    <FontAwesomeIcon icon={faRotateLeft} title='Undo Changes' className={`${hasChanges ? 'text-primary' : 'text-secondary'} lh-1 px-1`} size="lg" style={{ cursor: hasChanges ? 'pointer' : 'default' }} onClick={undoChanges} />
                                </>
                                :
                                null
                            }
                            <FontAwesomeIcon icon={faFloppyDisk} title='Save Changes' className={`${hasChanges && !invalidCode ? 'text-primary' : 'text-secondary'} lh-1 px-1`} size="lg" style={{ cursor: hasChanges && !invalidCode ? 'pointer' : 'default' }} onClick={createNew ? createEvent : saveEvent } />
                        </li>
                    </ul>
                </div>
                <div className="row g-sm-3 pb-3">
                    <label htmlFor="txtCode" className="col-auto col-form-label col-form-label-lg">Event Code (For URL):</label>
                    <div className="col-md-auto col-sm-12">
                        <input type="text" required className={`form-control form-control-lg`} id="txtCode" value={eventData ? eventData.code : ''} onChange={(e) => { setEventData({ ...eventData, code: e.target.value }); setHasChanges(true); }} onBlur={checkCode} />
                    </div>
                    <div className={`invalid-feedback ${invalidCode ? 'd-block' : ''}`}>
                        Invalid code or code already in use.  Code must not have any spaces or special characters.
                    </div>
                </div>
                <div className="card mb-5">
                    <div className="row flex-lg-row align-items-center g-0 py-0 shadow-lg">
                        <div className="position-relative col-12 col-sm-12 col-lg-6 text-center">
                            <EventEditImage eventImageUrl={eventData.imageUrl} saved={savedImage} />
                            <img style={{maxHeight:'400px'}} src={eventData && eventData.imageUrl ? eventData.imageUrl : './assets/images/hyetech_logo2_4color.png'} className="img-fluid rounded-start" alt="..." />
                        </div>
                        
                        <div className="col-12 col-sm-12 col-lg-6 ps-4">
                            <div className="card-body">
                                {!eventData.title ? 
                                    <div className="display-5 col-12 border-2 rounded border-secondary-subtle text-center text-muted py-2" style={{ borderStyle: "dashed" }}>
                                        Add title
                                        <EventEditTitle eventTitle={eventData.title} saved={savedTitle} />
                                    </div>
                                    :
                                    <div className="display-5 fw-bold text-body-emphasis lh-1 mb-3">
                                        {eventData.title}
                                        <EventEditTitle eventTitle={eventData.title} saved={savedTitle} />
                                    </div>
                                    }
                                <p className="lead pt-3">
                                    {getEventDates() ?
                                        <>
                                            <FontAwesomeIcon icon={faCalendar} /> {getEventDates()} &nbsp;
                                            {getEventTimes() ? <><FontAwesomeIcon icon={faClock} /> {getEventTimes()}</> : null}
                                            <EventEditDates eventDates={{ startDate: eventData.startDate, startTime: eventData.startTime, endDate: eventData.endDate, endTime: eventData.endTime }} saved={savedDates} />
                                        </>
                                    :
                                        <div className="col border-2 rounded border-secondary-subtle text-center text-muted py-2" style={{ borderStyle: "dashed" }}>
                                            Enter Dates
                                            <EventEditDates eventDates={{ startDate: eventData.startDate, startTime: eventData.startTime, endDate: eventData.endDate, endTime: eventData.endTime }} saved={savedDates} />
                                        </div>
                                        }

                                    {eventData.venue || eventData.room
                                        ?
                                        <>
                                            <br />
                                            <FontAwesomeIcon icon={faLocationDot} />&nbsp;
                                            {eventData.venue ?? eventData.room}
                                        </>
                                        :
                                        null}
                                </p>
                                <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                                    {/*<button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="row flex-lg-row align-items-center g-5 py-5">*/}
                {/*    <div className="col-12 col-sm-12 col-lg-6">*/}
                {/*        <img src="https://www.shutterstock.com/shutterstock/photos/2062252346/display_1500/stock-vector-abstract-modern-business-conference-design-template-with-lines-minimal-flyer-layout-eps-vector-2062252346.jpg" className="d-block mx-lg-auto img-fluid" alt="" loading="lazy" />*/}
                {/*    </div>*/}
                {/*    <div className="col-12 col-sm-12 col-lg-6">*/}
                {/*        <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3">{eventData.title}</h1>*/}
                {/*        <p className="lead pt-3">*/}
                {/*            <FontAwesomeIcon icon={faCalendar} /> {getEventDates()} &nbsp;*/}
                {/*            {getEventTimes() ? <><FontAwesomeIcon icon={faClock} /> {getEventTimes()}</> : null}*/}
                {/*            {eventData.venue || eventData.room*/}
                {/*                ?*/}
                {/*                <>*/}
                {/*                    <br />*/}
                {/*                    <FontAwesomeIcon icon={faLocationDot} />&nbsp;*/}
                {/*                    {eventData.venue ?? eventData.room}*/}
                {/*                </>*/}
                {/*                :*/}
                {/*                null}*/}
                {/*        </p>*/}
                {/*        <div className="d-grid gap-2 d-md-flex justify-content-md-start">*/}
                {/*            <button type="button" className="btn btn-primary btn-lg px-4 me-md-2" onClick={modalToggle}>Register</button>*/}
                {/*            */}{/*<button type="button" className="btn btn-outline-secondary btn-lg px-4">Default</button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="row mt-5">
                    <div className="col-12 col-md-8 col-lg-8 pb-3">
                        <h3 className="text-muted text-secondary">
                            Short Description
                            <EventEditDescription eventDescription={eventData.shortDescription} saved={savedShortDescription} />
                        </h3>
                        {eventData.shortDescription
                            ?
                            <div className="lead" style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: eventData.shortDescription }} />
                            :
                            <div className="col-12 border-2 rounded border-secondary-subtle text-center text-muted py-5" style={{borderStyle: "dashed"} }>
                                Add short description
                            </div>
                        }
                        <h3 className="text-muted text-secondary mt-3">
                            Description
                            <EventEditDescription eventDescription={eventData.description} saved={savedDescription} />
                        </h3>
                        {eventData.description
                            ?
                            <div className="lead" style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: eventData.description }} />
                            :
                            <div className="col-12 border-2 rounded border-secondary-subtle text-center text-muted py-5" style={{ borderStyle: "dashed" }}>
                                Add description
                            </div>
                        }
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <h4 className="text-muted text-secondary">
                            Location
                            <EventEditLocation eventLocation={{ venue: eventData.venue, room: eventData.room, address: eventData.address, city: eventData.city, state: eventData.state, zip: eventData.zip }} saved={savedLocation} />
                        </h4>
                        {eventData && (eventData.venue || eventData.room || eventData.address || eventData.city || eventData.state || eventData.zip)
                            ?
                            <p className="lead">
                                {eventData.venue ? <>{eventData.venue}<br /></> : null}
                                {eventData.room ? <>{eventData.room}<br /></> : null}
                                {eventData.address ? <>{eventData.address}<br /></> : null}
                                {eventData.city ? <>{eventData.city}</> : null}{eventData.city && eventData.state ? ', ' : null} {eventData.state ? <>{eventData.state}</> : null} {eventData.zip ? <>{eventData.zip}</> : null}{eventData.city || eventData.state || eventData.zip ? <br /> : null}
                            </p>
                            :
                            'TBD'
                        }
                    </div>
                </div>
            </div>
            {/*{testDesc}*/}
            {/*<ReactQuill*/}
            {/*    value={testDesc}*/}
            {/*    //onChange={setTestDesc}*/}
            {/*    onChange={(value, _delta, source) => {*/}
            {/*        if (source === "user") setTestDesc(value);*/}
            {/*    }}*/}
            {/*    theme="snow"*/}
            {/*    placeholder={'Enter event description here'}*/}
            {/*    formats={[*/}
            {/*        'font', 'header',*/}
            {/*        'bold', 'italic', 'underline', 'strike', 'blockquote',*/}
            {/*        'list', 'bullet', 'indent',*/}
            {/*        'link', 'image'*/}
            {/*    ]}*/}
            {/*    modules={{*/}
            {/*        toolbar: [*/}
            {/*            [{ 'header': [1, 2, 3, false] }],*/}
            {/*            [{ 'font': [] }],*/}
            {/*            [{ size: [] }],*/}
            {/*            [{ 'color': [] }, { 'background': [] }],*/}
            {/*            [{ 'align': [] }],*/}
            {/*            ['bold', 'italic', 'underline', 'strike', 'blockquote'],*/}
            {/*            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' },*/}
            {/*            [{ 'script': 'sub' }, { 'script': 'super' }],*/}
            {/*            { 'indent': '-1' }, { 'indent': '+1' }],*/}
            {/*            ['link', 'image', 'video'],*/}
            {/*            ['clean']                                ],*/}
            {/*    }}*/}

            {/*/>*/}

            {/*<HtmlEditor content={testDesc} />*/}
        </>
    );
};
