import { useState, useEffect } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
//import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import axios from 'axios';


export const useApiFetch = () => {
    //const authHeader = useAuthHeader();
    //const isAuthenticated = useIsAuthenticated();

    const client = axios.create({

    })

    useEffect(() => {
    //    if (isAuthenticated())
    //        //set the axios defaults
    //        client.defaults.headers.common['Authorization'] = authHeader;
    });

    return client;
}
export default useApiFetch;

const updateOptions = (options, authUser) => {

    const update = { ...options };
    if (authUser) {
        update.headers = {
            ...update.headers,
            Authorization: `Bearer ${authUser.token}`,
        };
    }
    return update;
}

const ApiFetch = (url, options) => {
    const authUser = useAuthUser();
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated()) {
        return fetch(url, updateOptions(options, authUser));
    }
    else
        return fetch(url, options);

}